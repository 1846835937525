import location from "../imgs/mappin.svg";
import open from "../imgs/clock.svg";
import { useTranslation } from "react-i18next";
import Button from "./Button";
const SightItem = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <>
      <div className="sight-item">
        <div className="number">{props.num}</div>
        <div className="text-wrap">
          <h2>{data.title}</h2>
          <p>{data.subtitle1}</p>
          {data.btn ? <Button type={props.type} t={t} /> : null}
        </div>
        <div className={`img-wrap ${data.class ? data.class : ""}`}>
          <img src={data.src} alt={data.alt} />
        </div>
      </div>
      {props.type === "normal" ? (
        <div className="sight-item-footer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#143551"
              fillOpacity="1"
              d="M0,160L80,154.7C160,149,320,139,480,149.3C640,160,800,192,960,181.3C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>
          <div className="bg-svg">
            <div className="location-wrap">
              <img className="icon" src={location} alt="icon" />
              <div className="loc-wrap">
                <p className="loc">{t("main1.sightsPage.location")}</p>
                <p className="place">{data.location}</p>
              </div>
            </div>
            <div className="hours-wrap">
              <img className="icon" src={open} alt="icon" />
              <div className="hour-wrap">
                <p className="open">{t("main1.sightsPage.open")}</p>
                {data.hours.map((e, i) => (
                  <p key={i} className="place">
                    {e}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {props.type === "ship" ? (
        <div className="sight-item-footer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#B83B3B"
              fillOpacity="1"
              d="M0,160L80,154.7C160,149,320,139,480,149.3C640,160,800,192,960,181.3C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>
          <div className="bg-svg" style={{ backgroundColor: "#b83b3b" }}>
            <div className="location-wrap">
              <img
                className="icon"
                style={{ backgroundColor: "#143551" }}
                src={location}
                alt="icon"
              />
              <div className="loc-wrap">
                <p className="loc">{t("main1.sightsPage.location")}</p>
                <p className="place">Dock 10, Március 15. tér, Budapest</p>
              </div>
            </div>
            {/* <div className="hours-wrap">
                    <img className="icon" src={open} alt="icon" />
                    <div className="hour-wrap">
                    <p className="open">{t("main1.sightsPage.open")}</p>
                    {data.hours.map((e, i) => (
                      <p key={i} className="place">
                      {e}
                      </p>
                      ))}
                      </div>
                    </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default SightItem;
