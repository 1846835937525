import SightItem from "./components/SightItem";
import logo from "./imgs/vb-logo_64.png";
import szabadsag from "./imgs/1024/szabadsag.webp";
import budai from "./imgs/1024/budai.webp";
import hajo from "./imgs/6.webp";
import halasz from "./imgs/1024/halasz.webp";
import hosok from "./imgs/1024/hosok.webp";
import lanchid from "./imgs/1024/lanchid.webp";
import szabadsaghid from "./imgs/1024/szabadsaghid.webp";
import parlament from "./imgs/1024/parlament.webp";
import szechenyi from "./imgs/1024/szechenyi.webp";
import zsinagoga from "./imgs/1024/zsinagoga.webp";
import bazilika from "./imgs/1024/bazilika.webp";

import { useState, useEffect } from "react";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import SightItemMobile from "./components/SightItemMobile";
import map from "./imgs/visitbudapest-map.webp";
import Partners from "./components/Partners";
const LandingPage = ({ t }) => {
  const navigate = useNavigate();
  const langData = [
    { short: "Hun", flag: "--hun", long: "Magyar", abbr: "hu" },
    { short: "Eng", flag: "--eng", long: "English", abbr: "en" },
    { short: "Ger", flag: "--ger", long: "Deutsch", abbr: "de" },
    { short: "Fra", flag: "--fra", long: "Français", abbr: "fr" },
    { short: "Ita", flag: "--ita", long: "Italiano", abbr: "it" },
    { short: "Esp", flag: "--spa", long: "Español", abbr: "es" },
    { short: "Chi", flag: "--chi", long: "中国", abbr: "zh" },
    { short: "Rus", flag: "--rus", long: "РУССКИЙ", abbr: "ru" },
    { short: "Rom", flag: "--rom", long: "Română", abbr: "ro" },
  ];
  const lang = i18next.language;
  const [langOpen, setLangOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentLang, setCurrentLang] = useState(
    langData.find((e) => e.abbr === lang)
      ? langData.find((e) => e.abbr === lang)
      : { short: "en", flag: "--eng" }
  );
  useEffect(() => {
    if (i18next.language === window.location.pathname.slice(1)) return;
    const availableLanguages = Object.values(langData).map((e) => e.abbr);

    if (availableLanguages.find((e) => e === window.location.pathname.slice(1))) {
      i18next
        .changeLanguage(window.location.pathname.slice(1))
        .then(() =>
          setCurrentLang(langData.find((e) => e.abbr === window.location.pathname.slice(1)))
        );
    } else navigate("/en");
  }, [lang]);
  useEffect(() => {
    let isMounted = true;
    window.addEventListener("resize", () => {
      if (isMounted) setWidth(window.innerWidth);
    });

    return () => {
      isMounted = false;
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);
  const sightsData = [
    {
      src: parlament,
      alt: t("main1.sightsPage.parlament.alt"),
      title: t("main1.sightsPage.parlament.title"),
      subtitle1: t("main1.sightsPage.parlament.desc"),
      location: "Budapest, Kossuth Lajos tér 1-3, 1055",
      hours: [
        t("main1.sightsPage.mon-fri") + " 08:00 - 18:00",
        t("main1.sightsPage.sat-sun") + " 08:00 - 16:00",
      ],
      btn: true,
    },
    {
      src: hosok,
      alt: t("main1.sightsPage.hosok.alt"),
      title: t("main1.sightsPage.hosok.title"),
      subtitle1: t("main1.sightsPage.hosok.desc"),
      location: "Budapest, Hősök tere, 1146",
      hours: [t("main1.sightsPage.szabadsag.open")],
    },
    {
      src: szabadsag,
      alt: t("main1.sightsPage.szabadsag.alt"),
      title: t("main1.sightsPage.szabadsag.title"),
      subtitle1: t("main1.sightsPage.szabadsag.desc"),
      location: "Budapest, Gellért hegy, 1118",
      class: "szab",
      btn: true,
      hours: [t("main1.sightsPage.szabadsag.open")],
    },
    {
      src: budai,
      alt: t("main1.sightsPage.budaivar.alt"),
      title: t("main1.sightsPage.budaivar.title"),
      subtitle1: t("main1.sightsPage.budaivar.desc"),
      location: "Budapest, Szent György tér 2, 1014",
      btn: true,
      hours: [t("main1.sightsPage.budaivar.open")],
    },
    {
      src: zsinagoga,
      alt: t("main1.sightsPage.dohany.alt"),
      title: t("main1.sightsPage.dohany.title"),
      subtitle1: t("main1.sightsPage.dohany.desc"),
      location: "Budapest, Dohány u. 2., 1074",
      hours: [t("main1.sightsPage.dohany.open.1"), t("main1.sightsPage.dohany.open.2")],
    },
    {
      src: halasz,
      alt: t("main1.sightsPage.halasz.alt"),
      title: t("main1.sightsPage.halasz.title"),
      subtitle1: t("main1.sightsPage.halasz.desc"),
      btn: true,
      location: "Budapest, Szentháromság tér, 1014",
      hours: [t("main1.sightsPage.halasz.open")],
    },
    {
      src: szechenyi,
      alt: t("main1.sightsPage.szechenyi.alt"),
      title: t("main1.sightsPage.szechenyi.title"),
      subtitle1: t("main1.sightsPage.szechenyi.desc"),
      location: "Budapest, Állatkerti krt. 9-11, 1146",
      hours: [t("main1.sightsPage.szechenyi.open.1"), t("main1.sightsPage.szechenyi.open.2")],
    },
    {
      src: szabadsaghid,
      alt: t("main1.sightsPage.szab.alt"),
      title: t("main1.sightsPage.szab.title"),
      subtitle1: t("main1.sightsPage.szab.desc"),
      btn: true,
      location: "Budapest, Fővám tér 1093",
      hours: [t("main1.sightsPage.szabadsag.open")],
    },

    {
      src: bazilika,
      alt: t("main1.sightsPage.baz.alt"),
      title: t("main1.sightsPage.baz.title"),
      subtitle1: t("main1.sightsPage.baz.desc"),
      location: "Budapest, Szent István tér 1, 1051",
      hours: [t("main1.sightsPage.baz.open.2"), t("main1.sightsPage.baz.open.3")],
    },
    {
      src: lanchid,
      alt: t("main1.sightsPage.lanc.alt"),
      title: t("main1.sightsPage.lanc.title"),
      btn: true,
      subtitle1: t("main1.sightsPage.lanc.desc"),
      location: "Budapest, Széchenyi Lánchíd, 1051",
      hours: [t("main1.sightsPage.szabadsag.open")],
      class: "lanc",
    },
  ];
  const shipData = {
    src: hajo,
    alt: t("main1.sightsPage.bonus.alt"),
    title: t("main1.sightsPage.bonus.title"),
    subtitle1: t("main1.sightsPage.bonus.desc"),
    class: "ship",
    btn: true,
  };
  return (
    <>
      <div className="visit-bp-page">
        <div className="hero">
          <div className="header">
            <img className="logo" src={logo} alt="logo"></img>
            <span>Visit Budapest</span>
            <div className="hamburger" onClick={() => setLangOpen(!langOpen)}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line short"></div>
            </div>
          </div>
          <div className={`lang-selector ${langOpen ? "" : "hidden"}`}>
            <p>Choose Language</p>
            <div className="close" onClick={() => setLangOpen(false)}></div>
            {langData.map((e, i) => {
              return (
                <div
                  className="lang-item"
                  key={i}
                  onClick={() => {
                    setLangOpen(false);
                    setCurrentLang(e);
                    i18next.changeLanguage(e.abbr).then(() => {
                      return navigate(`/${e.abbr}`);
                    });
                  }}>
                  <a style={{ display: "none" }} hrefLang={e.abbr}></a>
                  <div className={`flag ${e.flag}`}></div>
                  <div className="lang">{e.long}</div>
                </div>
              );
            })}
          </div>
          <div className="hero-img">
            <h1>
              {t("main1.hero.h1")}
              <br></br>
              {t("main1.hero.h2")} <br></br>
              {t("main1.hero.h3")}
            </h1>

            <div className="small-img"></div>
          </div>

          <div
            className="hero-arrow"
            onClick={() => {
              const ele = document.getElementById("pois");
              ele.scrollIntoView({ behavior: "smooth", block: "start" });
            }}>
            <div className="arrow-top"></div>
            <span>{t("main1.sightsPage.more")}</span>
            <div className="arrow-btm"></div>
          </div>
        </div>
        <div className="guide-wrap">
          <div className="guide">
            <div className="guide-body">
              <div className="guide-head">
                <p>
                  {t("main1.sightsPage.guideH")}{" "}
                  <span>
                    <br></br>
                    {t("main1.sightsPage.guideH2")}{" "}
                  </span>
                </p>
              </div>
              <p>{t("main1.sightsPage.guideP")} </p>
              <div className="guide-bg"></div>
            </div>
          </div>
        </div>
        <div className="pois-wrap" id="pois">
          {sightsData.map((e, i) =>
            width >= 1000 ? (
              <SightItem key={i} type="normal" data={e} num={i < 9 ? "0" + (i + 1) : i + 1} />
            ) : (
              <SightItemMobile type="normal" key={i} data={e} num={i < 9 ? "0" + (i + 1) : i + 1} />
            )
          )}
        </div>
        <div className="ship-section" id="ship">
          {width >= 1000 ? (
            <SightItem data={shipData} type="ship" num="+1" numStyle="gold" />
          ) : (
            <SightItemMobile data={shipData} num="+1" numStyle="gold" type="ship" />
          )}
        </div>
        <div className="map-section">
          <div className="map-wrap">
            <h2>
              <span className="first">{t("main1.sightsPage.mapH")}</span>
              <br></br>
              <span className="second">{t("main1.sightsPage.mapH2")}</span>
            </h2>
            <img src={map} alt="map" />
          </div>
        </div>
        <footer>
          <div className="logo-wrap">
            <img className="logo" src={logo} alt="brand"></img>
            <div className="brand-text-wrap">
              <h3>Visit Budapest</h3>
              <p className="text">
                {new Date().getFullYear()} - <span>All rights reserved</span>
              </p>
            </div>
          </div>
          <p className="partners">{t("main1.sightsPage.partner")}</p>
          <Partners lang={lang} />
        </footer>
      </div>
    </>
  );
};
export default LandingPage;
