import location from "../imgs/mappin.svg";
import open from "../imgs/clock.svg";
import { useTranslation } from "react-i18next";
import Button from "./Button";
const SightItemMobile = (props) => {
  const { t } = useTranslation();

  // {
  //   src: balna,

  //   //maker: "Fred Romero",
  //   alt: t("AltTexts.Home.Builds.bud"),
  //   title: t("main1.sightsPage.balna.title"),
  //   subtitle1: t("main1.sightsPage.balna.desc"),
  //   id: 11,
  //   built: t("Pages.Sights.built"),
  //   act: t("Pages.Sights.acts"),
  //   year: "2009-2013",
  //   name: "Kas Oosterhuis, Lénárd Ilona",
  //   class: "balnaClass",
  //   //link: "https://www.flickr.com/photos/129231073@N06/",
  // }
  const { data } = props;
  return (
    <>
      <div className="sight-item mobile">
        <div className="number">{props.num}</div>
        <h2>{data.title}</h2>
        <div className={`img-wrap ${data.class ? data.class : ""}`}>
          <img src={data.src} alt={data.alt} />
        </div>
        <div className="text-wrap">
          <p>{data.subtitle1}</p>
          {data.btn ? <Button t={t} type={props.type} /> : null}
        </div>
        {props.type === "normal" ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#143551"
                fillOpacity="1"
                d="M0,160L80,154.7C160,149,320,139,480,149.3C640,160,800,192,960,181.3C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
            <div className="bg-svg">
              <div className="location-wrap">
                <img className="icon" src={location} alt="icon" />
                <div className="loc-wrap">
                  <p className="loc">{t("main1.sightsPage.location")}</p>
                  <p className="place">{data.location}</p>
                </div>
              </div>
              <div className="hours-wrap">
                <img className="icon" src={open} alt="icon" />
                <div className="hour-wrap">
                  <p className="open">{t("main1.sightsPage.open")}</p>
                  {data.hours.map((e, i) => (
                    <p key={i} className="place">
                      {e}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {props.type === "ship" ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#b83b3b"
                fillOpacity="1"
                d="M0,160L80,154.7C160,149,320,139,480,149.3C640,160,800,192,960,181.3C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
            <div className="bg-svg" style={{ backgroundColor: "#b83b3b" }}>
              <div className="location-wrap">
                <img
                  className="icon"
                  style={{ backgroundColor: "#143551" }}
                  src={location}
                  alt="icon"
                />
                <div className="loc-wrap">
                  <p className="loc">{t("main1.sightsPage.location")}</p>
                  <p className="place">Dock 10, Március 15. tér, Budapest</p>
                </div>
              </div>
              {/* <div className="hours-wrap">
                        <img className="icon" src={open} alt="icon" />
                        <div className="hour-wrap">
                          <p className="open">{t("main1.sightsPage.open")}</p>
                          {data.hours.map((e, i) => (
                            <p key={i} className="place">
                              {e}
                            </p>
                          ))}
                        </div>
                      </div> */}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default SightItemMobile;
