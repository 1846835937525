import ssBpLogo from "../imgs/icon-parlament.svg";
import dcLogo from "../imgs/dc-logo-white.svg";
import seta from "../imgs/setahajo-logo--white@256px.png";
import kisf from "../imgs/kisfaludy_logo_white.png";

const Partners = ({ lang }) => {
  return (
    <div className="partners-wrap ">
      <div className="partner-icon ">
        <a className="dc" href={`https://dunacruises.com/${lang}`}>
          <img src={dcLogo} alt="Duna Cruises" />
        </a>
      </div>

      <div className="partner-icon ">
        <a className="kisf" href={`https://kisfaludygozos.hu`}>
          <img src={kisf} alt="Kisfaludy Gőzös" />
        </a>
      </div>
      <div className="partner-icon ss-bp">
        <a className="ss-bp" href={`https://sightseeing-budapest.com/`}>
          <img src={ssBpLogo} alt="Sightseeing Budapest" />
          <span>Sightseeing Budapest</span>
        </a>
      </div>
      <div className="partner-icon ">
        <a className="setahajo" href={`https://setahajo.hu/`}>
          <img src={seta} alt="Sétahajó" />
        </a>
      </div>
    </div>
  );
};
export default Partners;
