import i18next from "i18next";
import icon from "../imgs/ship-icon-white.svg";
const Button = ({ t, type }) => {
  return type === "ship" ? (
    <div className="ship-btn">
      <a href="https://fareharbor.com/embeds/book/dunacruises/items/calendar/?full-items=yes&flow=823770">
        {t("main1.schedule.tickets")}
      </a>
      <img src={icon} alt="" />
    </div>
  ) : (
    <div
      className="ship-btn"
      onClick={() => {
        const ele = document.getElementById("ship");
        ele.scrollIntoView({ behavior: "smooth" });
      }}>
      <a style={{ pointerEvents: "none" }} href="#ship">
        {t("main1.sightsPage.btn")}
      </a>
      <img src={icon} alt="" />
    </div>
  );
};
export default Button;
