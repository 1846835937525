import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./LandingPage";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function App() {
  const languages = ["hu", "en", "es", "fr", "de", "it", "ru", "zh", "ro"];
  let lang = i18next.language;
  const { t } = useTranslation();
  if (!languages.find((e) => e === lang)) {
    lang = "en";
  }
  const location = useLocation();
  useEffect(() => {
    location &&
      location.pathname &&
      location.pathname !== "/" &&
      window.fathom &&
      window.fathom.trackPageview({
        url: location.pathname,
      });
  }, []);
  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }}>
        <meta property="og:title" content={t("head.metaTitle")} />
        <title>{t("head.title")}</title>
        <meta property="og:description" content={t("head.metaDesc")} />
        <meta name="description" content={t("head.desc")} />
        <meta property="og:url" content={window.location} />
      </Helmet>

      <Routes>
        {languages.map((e, i) => {
          return <Route key={i} path={e} element={<LandingPage t={t} />} />;
        })}
        <Route path="/" element={<Navigate to={lang} replace={true} />} />
        <Route path="*" element={<Navigate to={lang} replace={true} />} />
      </Routes>
    </>
  );
}

export default App;
